<template>
  <div>
    <h1 class="display-1 font-weight-bold mx-1 mb-3">
      Xero Invoices
    </h1>
    <p>
      This page manages the interface with Xero Invoices
    </p>
    <p>
      We've found {{newInvoices.length}} new invoices
    </p>
    <v-btn class="mb-2" color="primary" :loading="fetchingInvoices" @click="fetchInvoiceData" >Fetch new Invoice Data</v-btn>
  </div>
</template>

<script>
import AccountingApi from '@/api/accounting'

export default {
  props: {
  },
  components: {
  },
  data () {
    return {
      newInvoices: [],
      invoices: [],
      fetchingInvoices: false
    }
  },
  async created () {
    const response = await AccountingApi.getNewInvoiceNumbers()
    this.newInvoices = response.data
  },
  computed: {
  },
  watch: {},
  methods: {
    async fetchInvoiceData () {
      this.fetchingInvoices = true
      const numInvs = Math.ceil(this.newInvoices.length / 50)
      for (let i = 0; i < numInvs; i++) {
        const newArr = this.newInvoices.splice(0, 50)
        const resp = await AccountingApi.updateXeroLineItems({ invoiceNumbers: newArr })
        this.invoices = this.invoices.concat(resp.data)
      }
      this.fetchingInvoices = false
    }
  }
}
</script>
